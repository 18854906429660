import React, { useState } from 'react';
import axios from 'axios';

import { Button } from './bulma';

// const LOCAL = 'http://localhost:5000/';
// const PROD = 'https://xt5du8gkxb.execute-api.us-east-1.amazonaws.com/prod/';
const HEROKU = 'https://website-analyzer-api.herokuapp.com/';

export default function Analyzer({ heading, subheading }) {
	const [ url, setURL ] = useState();
	const [ loading, setLoading ] = useState();
	const [ data, setData ] = useState();

	const analyze = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${HEROKU}?url=${url}`);
			setData(data);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const handleChange = (e) => {
		setURL(e.target.value);
	};

	if (!data)
		return (
			<div className="columns is-mobile is-centered">
				<div className="column is-half-desktop has-text-centered">
					<h1 className="title is-size-1 has-text-centered has-text-weight-bold is-spaced">{heading}</h1>
					<h2 className="subtitle has-text-centered is-size-4-mobile is-size-4">{subheading}</h2>
					<br />
					<div className="field has-addons is-hidden-mobile">
						<div className="control is-expanded">
							<input
								name="url"
								onChange={handleChange}
								id="url"
								required={true}
								type="text"
								placeholder="Enter your website URL"
								className="input is-medium"
							/>
						</div>
						<div className="control">
							<Button loading={loading} size="medium" action={analyze}>
								Estimate savings
							</Button>
						</div>
					</div>
					<div className="field is-hidden-desktop is-hidden-tablet">
						<div className="control is-expanded">
							<input
								name="url"
								onChange={handleChange}
								id="url"
								required={true}
								type="text"
								placeholder="Enter your website URL"
								className="input is-medium"
							/>
						</div>
						<br />
						<div className="control">
							<Button block loading={loading} size="large" action={analyze}>
								Estimate savings
							</Button>
						</div>
					</div>
				</div>
			</div>
		);

	if (url && data && data.length > 0) {
		const [ provider ] = data;
		return (
			<div className="columns is-mobile is-centered">
				<div className="column is-two-thirds is-hidden-mobile">
					<h2 className="subtitle  has-text-centered is-size-4">
						Looks like you are processing payments with {provider}.
					</h2>

					<h1 className="title has-text-centered has-text-weight-bold is-spaced" style={{ fontSize: '65px' }}>
						You could save as much as 30% on payment fees today!
					</h1>

					<h2 className="subtitle has-text-centered is-size-4">
						It means an extra profit of $1,000 on every $100,000 in sales.
					</h2>
					<h6 className="has-text-centered">
						*Disclaimer: This is a rough estimate. To give you the exact numbers, we need to see your
						processing statement. But we promise you that you will save money in any case. And if you are
						not happy, you can cancel at any moment, free!
					</h6>
					<br />
					<div className="buttons  is-centered">
						<p className="control">
							<a href={`https://app.payment.ninja/signup`} className="button is-primary is-large">
								<strong>START SAVING</strong>
							</a>
						</p>
						<p className="control">
							<a
								href="https://calendly.com/finhub/payment-ninja-demo"
								className="button is-text is-large"
							>
								Free consultation
							</a>
						</p>
					</div>
				</div>
				<div className="column is-hidden-desktop">
					<br />

					<h2 className="subtitle  has-text-centered is-size-4">
						Looks like you are processing payments with {provider}.
					</h2>
					<br />
					<h1 className="title has-text-centered has-text-weight-bold is-spaced">
						You could save as much as 30% on payment fees today!
					</h1>
					<h2 className="subtitle has-text-centered is-size-4">
						It means an extra profit of $1,000 on every $100,000 in sales.
					</h2>
					<h6 className="has-text-centered is-size-7">
						*Disclaimer: This is a rough estimate. To give you the exact numbers, we need to see your
						processing statement. But we promise you that you will save money in any case. And if you are
						not happy, you can cancel at any moment, free!
					</h6>
					<br />
					<div className="buttons  is-centered">
						<p className="control">
							<a href={`https://app.payment.ninja/signup`} className="button is-primary is-large">
								<strong>START SAVING</strong>
							</a>
						</p>
						<p className="control">
							<a
								href="https://calendly.com/finhub/payment-ninja-demo"
								className="button is-text is-large"
							>
								Free consultation
							</a>
						</p>
					</div>
				</div>
			</div>
		);
	} else
		return (
			<div className="columns is-mobile is-centered">
				<div className="column is-two-thirds is-hidden-mobile">
					<h2 className="subtitle has-text-centered is-size-4">
						Sorry, we couldn't determine your current payment provider.
					</h2>
					<h1 className="title has-text-centered has-text-weight-bold is-spaced" style={{ fontSize: '65px' }}>
						We promise that you will save money in any case.
					</h1>
					<h2 className="subtitle has-text-centered is-size-4">
						And if you are not happy, you can cancel at any moment, free!
					</h2>

					<div className="buttons  is-centered">
						<p className="control">
							<a href={`https://app.payment.ninja/signup`} className="button is-primary is-large">
								<strong>START SAVING</strong>
							</a>
						</p>
						<p className="control">
							<a
								href="https://calendly.com/finhub/payment-ninja-demo"
								className="button is-text is-large"
							>
								Free consultation
							</a>
						</p>
					</div>
				</div>
				<div className="column is-hidden-desktop is-hidden-tablet">
					<br />
					<h2 className="subtitle has-text-centered is-size-4">
						Sorry, we couldn't determine your current payment provider.
					</h2>
					<br />
					<h1 className="title has-text-centered has-text-weight-bold is-spaced">
						We promise that you will save money in any case.
					</h1>
					<h2 className="subtitle has-text-centered is-size-4">
						And if you are not happy, you can cancel at any moment, free!
					</h2>

					<div className="buttons  is-centered">
						<p className="control">
							<a href={`https://app.payment.ninja/signup`} className="button is-primary is-large">
								<strong>START SAVING</strong>
							</a>
						</p>
						<p className="control">
							<a
								href="https://calendly.com/finhub/payment-ninja-demo"
								className="button is-text is-large"
							>
								Free consultation
							</a>
						</p>
					</div>
				</div>
			</div>
		);
}
